import React, { useEffect, useState,useContext } from 'react';

import Nav from '../../components/nav';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import BannerSubscribe from '../../components/bannerSubscribe';
import { CartContext } from '../../context/cart';
import withCart from '../../HOC/withCart';


import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon,ShoppingBagIcon} from '@heroicons/react/24/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const Contact = ({getTotalItems,cart, addToCart, removeFromCart, updateCartItem,clearCart}) => {
    const [selectedOption, setSelectedOption] = useState(getTotalItems()>0?'contact-enquiry':'contact-other');
    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionMessage, setSubmissionMessage] = useState('');
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    const recaptchaSiteKey = window.wpReactTheme.contactSettings.captcha_site_key;

    // const [captchaValue, setCaptchaValue] = useState('');
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const captchaValue = window.grecaptcha.getResponse(); 
        const formData = new FormData();

        setIsSubmitting(true);
        setSubmissionMessage('');
        setSubmissionSuccess(false);

        formData.append('action', 'send_message');
        formData.append('subject', subject);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('selectedOption', selectedOption);
        const cartJson = JSON.stringify(cart);
        formData.append('cart', cartJson);
        formData.append('g-recaptcha-response', captchaValue);
    
        try {
            const handlerUrl = '/wp-content/themes/weapons/contact-handler.php';

            const response = await fetch(handlerUrl, {
                method: 'POST',
                body: formData // Send formData directly
            });
    
            const responseData = await response.json();
            if (responseData.status === 'success') {

                await clearCart();
                setSubject('');
                setEmail('');
                setMessage('');
                setSelectedOption('contact-enquiry'); // or any default value
                setSubmissionMessage(responseData.message);
                setSubmissionSuccess(true);
            }else if(responseData.status === 'reCAPTCHA has failed'){
                setSubmissionMessage('reCAPTCHA has failed');
                setSubmissionSuccess(false);
            } else {
                setSubmissionMessage(responseData.message);
                setSubmissionSuccess(false);
            }
            // Handle success
        } catch (error) {
            console.error('Error:', error);
            setSubmissionMessage('Failed to send message. Please try again.');
            setSubmissionSuccess(false);
            // Handle error
        }
        setIsSubmitting(false);
    };

    // ############ MENU ############
    const [menuItems, setMenuItems] = useState([]);
    const [logoURL, setLogoURL] = useState('/images/logo.svg');  // Default logo
    useEffect(() => {
        // Fetch the menu items from WordPress REST API
        fetch('/wp-json/menus/v1/menus/MainMenu')
          .then((response) => response.json())
          .then((data) => {
            setMenuItems(data.items);
          });

        // Fetch the theme logo
        fetch('/wp-json/theme/v1/logo')
            .then((response) => response.json())
            .then((data) => {
                // console.dir(data)
                setLogoURL(data.logo);
            });



    }, []);





    const [featuredImage, setFeaturedImage] = useState('');
    // const totalItems = props.total();
    // console.log(totalItems); // Log the result
    const subscriberImage = window.wpReactTheme.subscribeSettings.banner_image;
    const textLine1 = window.wpReactTheme.subscribeSettings.text_line_1;
    const textLine2 = window.wpReactTheme.subscribeSettings.text_line_2;


    useEffect(() => {
        fetch('/wp-json/wp/v2/pages?slug=contact')
        .then(response => response.json())
        .then(data => {
            if (data && data.length > 0 && data[0].featured_media) {
            fetch(`/wp-json/wp/v2/media/${data[0].featured_media}`)
                .then(response => response.json())
                .then(mediaData => {
                setFeaturedImage(mediaData.source_url);
                });
            }
        });
    }, []);

    return (<div>
        
        <Disclosure as="nav" className="bg-gray-800">
    {({ open }) => (
      <>
      <div className="bg-dark w-full">
        <div className="
            w-full
            h-14

            md:w-full
            
            lg:w-[1000px]
            lg:mx-auto

            xl:w-[1200px]

            flex
            justify-between
            ">
                <div className="ml-2 md:ml-3 lg:ml-0 mt-2">
                    <a href="/">
                        <img src={logoURL} alt="logo" className="h-8 md:h-10" />
                    </a>
                </div>
                <div className="hidden mt-4 mr-10 sm:block">
                    <ul className="flex space-x-12">
                        {menuItems?.map((item) => (
                        <li key={item.ID}>
                            <a href={item.url} className="text-white text-sm hover:text-main transition">{item.title}</a>
                        </li>
                        ))}
                        <li className="flex hover:cursor-pointer hover:opacity-30 transition">
                            <a href="/cart" className="flex">
                              <ShoppingBagIcon className="w-6 h-6 text-white" />
                              <div className="text-black ml-1 mt-0.5 pt-[3px] text-xs bg-white px-2 rounded-full">{getTotalItems()}</div>
                            </a>
                        </li>
                    
                    </ul>

                    {/* <button
                    onClick={handleTest}
                    className="text-white bg-red-500">Test Add Cart</button> */}
                </div>

                <div className="inset-y-0 mr-4 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                    </Disclosure.Button>
                </div>
        </div>
        <div className="
            w-full
            bg-[#353C42]

            md:w-full
            md:bg-[#353C42]
            
            lg:w-[1000px]
            lg:mx-auto
            lg:bg-[#353C42]

            xl:w-[1200px]
            xl:bg-[#353C42]

            h-[5px]
            flex
            ">&nbsp;</div>
    </div>



    <Disclosure.Panel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
        {menuItems?.map((item) => (
            <Disclosure.Button
            key={`mobile-${item.ID}`}
            as="a"
            href={item.url}
            className={classNames(
                item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block rounded-md px-3 py-2 text-base font-medium'
            )}
            // aria-current={item.current ? 'page' : undefined}
            >
            {item.title}
            </Disclosure.Button>
        ))}
        </div>
    </Disclosure.Panel>

    </>
    )}
    </Disclosure>



        <div className="w-full relative h-[180px] md:h-[260px] overflow-hidden">
            <div className="
                h-[full]
                w-full
                md:w-full
                
                lg:w-[1000px]
                lg:mx-auto

                xl:w-[1200px]

                px-12 md:px-20 py-10
                absolute z-11 text-white
                flex flex-col items-start justify-end
                left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
                ">
                    <h1 className="text-2xl md:text-4xl font-bold leading-[40px] md:leading-[46px]">Contact us</h1>
            </div>
            <div className="w-full h-full bg-black opacity-70 z-10 absolute">&nbsp;</div>
                {/* <img src="images/img-13.png" 
                 alt="hero" 
                 className="w-full h-full object-cover absolute z-0" />  */}
                 {featuredImage && <img src={featuredImage} alt="Featured" className="w-full h-full object-cover absolute z-0" />}
        </div>


        <div className="w-full bg-[#1C1C1C] py-8">
        <div className="
        w-full
        px-0
        lg:px-0
        md:w-full
        lg:w-[1000px]
        lg:mx-auto
        xl:w-[1200px]
        flex
        items-center
        text-white
            ">


                <div className="container py-8">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6 space-y-4 pr-8 px-8 md:px-0 ">
                            <h2 className="font-bold">WEAPON INDUSTIES CO., LTD (WI)</h2>
                            <p className="text-white/50">888 Moo.3, Banmai Subdistrict, 
                            Pakkred district, Nonthaburi, 11120 
                            THAILAND </p>
                            <p className="text-white/50">Tel. (+66) 02 279 8888 
                            Email: sales@weapons.co.th</p>
                        </div>

                        <div className="col-span-12 md:col-span-6 px-8 md:px-0 mt-8 md:mt-0 flex justify-center">

                            <div className="w-full xl:w-3/4">
                                
                            {
                                submissionSuccess ? (
                                    <div className="w-full bg-[#3A3A3A] px-5 py-5 rounded-lg">
                                        <h2 className="text-xl font-bold text-[#2E9908]">Email Sent Successfully</h2>
                                        <p className="mt-2 text-white/60 text-sm">
                                            Thank you for reaching out to us. Our team will review your message and get back to you within 3-5 business days. Please make sure to check your email inbox as well as the junk or spam folder for our response.
                                        </p>
                                    </div>
                                ) : (
                                    <form onSubmit={handleSubmit}>


                                    <div className="w-full bg-[#3A3A3A]">
                                        <select className="text-[#B3B3B3] w-full bg-[#3A3A3A] py-2 px-3"
                                        onChange={handleSelectChange}
                                        value={selectedOption}
                                        >
                                            <option value="contact-enquiry" className="">Contact - Enquiry</option>
                                            <option value="contact-sales">Contact - Sales</option>
                                            <option value="contact-other">Other</option>
                                        </select>
                                    </div>

                                    {selectedOption === 'contact-enquiry' && (
                                        <div className="w-full mt-2">
                                            <div className="w-full bg-[#3A3A3A] text-[#B3B3B3] py-2 px-3">
                                                Your Selected Products : {getTotalItems()} 
                                            </div>
                                        </div>
                                    )}

                                    <div className='w-full mt-2'>
                                        <input type="text" 
                                        value={subject} onChange={(e) => setSubject(e.target.value)}
                                        placeholder='Subject' className="w-full bg-[#3A3A3A] text-[#B3B3B3] py-2 px-3" />
                                    </div>

                                    <div className='w-full mt-2'>
                                        <input type="text" 
                                        value={email} onChange={(e) => setEmail(e.target.value)}
                                        placeholder='Email' className="w-full bg-[#3A3A3A] text-[#B3B3B3] py-2 px-3" />
                                    </div>

                                    <div className='w-full mt-2'>
                                        <textarea 
                                        value={message} onChange={(e) => setMessage(e.target.value)}
                                        className="w-full h-[150px] resize-none bg-[#3A3A3A] text-[#B3B3B3] py-2 px-3" />
                                    </div>

                                    <div className="g-recaptcha mt-2" 
                                        data-sitekey={recaptchaSiteKey}>
                                    </div>

                                    <div className='w-full mt-2'>
                                        <button type="submit" className="bg-main hover:bg-main-100 transition text-white px-6 py-3 font-bold">
                                            {isSubmitting ? 'Sending...' : 'Send Message'}
                                        </button>
                                    </div>

                                    {
                                        submissionMessage && submissionSuccess === false && (
                                            <div className="w-full">
                                                <div className="px-4 text-sm text-red-500">
                                                    {submissionMessage}
                                                </div>
                                            </div>
                                        )
                                    }
                                    


                                    </form>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>



            </div>
        </div>

        <BannerSubscribe line1={textLine1} line2={textLine2} images={subscriberImage} />

        <Footer />
    </div>)
}

export default withCart(Contact)