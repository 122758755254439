
export default function BannerSubscribe({
    line1,
    line2,
    images,
}){


    return (<div className="w-full my-8">
        <div className="
        w-full
        bg-green-500w
        px-0
        lg:px-0
        md:w-full
        lg:w-[1000px]
        lg:mx-auto
        xl:w-[1200px]
        flex
        items-center
        text-dark
        relative
            ">
            
            <div className="w-full h-[400px] md:h-[150px] mx-2 md:mx-0 relative">

                <div className="z-12 w-full h-full absolute text-white flex flex-col justify-center md:flex-row md:items-center md:justify-between">
                    <div className="text-center ml-8 md:text-left md:ml-8 mb-4 md:mb-0 mx-4">
                        {line1?<div className="text-2xl font-bold">{line1}</div>:null}
                        {line2?<div className="text-xl">{line2}</div>:null}
                    </div>
                    <div className="text-center mr-8 md:text-right md:mr-8 mt-4 md:mt-0">
                        <button className="bg-main hover:bg-main-100 transition text-white px-6 py-3 font-bold">
                        Subscribe
                        </button>
                    </div>
                </div>

                <div className="w-full h-full z-10">
                    <div className="relative w-full h-full">
                        <div className="bg-black/70 w-full h-full absolute z-11">&nbsp;</div>
                        <img src={
                            images?images:'/wp-content/themes/weapons/images/bannerSubscriber.png'
                        } className="object-cover w-full h-full absolute z-10" />
                    </div>
                </div>
            </div>

        </div>
    </div>)
}